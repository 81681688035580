import { useState } from 'react';
import { validateField } from '../utils/validators';

const useFormValidation = (initialState) => {
  // console.log(initialState)
  const [values, setValues] = useState(initialState);
  const [initialValues, setInitialValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    const newValues = { ...values, [field]: value };
    setValues(newValues);
    const error = validateField(field, value, newValues);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  };

  const handleBlur = (field) => {
    const value = values[field];
    const error = validateField(field, value, values);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => !error) &&
      Object.values(values).every((value) => value !== '')
    );
  };

  const isFormChanged = () => {
    // console.log(initialValues)
    return JSON.stringify(values) !== JSON.stringify(initialValues);
  };

  const setInitialFormValues = (newInitialValues) => {
    setValues(newInitialValues);
    setInitialValues(newInitialValues);
    setErrors({});
  };

  return {
    values,
    errors,
    handleChange,
    handleBlur,
    isFormValid,
    isFormChanged,
    setInitialFormValues,
    setErrors
  };
};

export default useFormValidation;
