import React, { useEffect, useState } from "react";
import {
  ChakraProvider, Text, Box, Heading,useDisclosure, UnorderedList, ListItem, Icon
} from '@chakra-ui/react';
import EditTechIndicatorModal from "./editTechindicator";
import useAdminCheck from '../../hooks/useAdminCheck';
import { useParams } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons'
import { get } from "../../services/api";

// Indicator Box Component
const IndicatorBox = ({ reportId, techIndicatorId, techIndicator, maturityLevel, techIndicatorScore, strengthSummary, improvementsNeeded, refreshData }) => {
  const { isOpen:isEditAssertionOpen, onOpen:onEditAssertionOpen, onClose:onEditAssertionClose } = useDisclosure();
  const { isAdmin } = useAdminCheck();

  const handleEditAssertionClick = () => {
    onEditAssertionOpen();
  };

  const statusColors = {
    "Good": "#12B76A",
    "Average": "#FED137",
    "Excellent": "#55B40C",
    "Needs Attention":"#D72626",
    "Fair":"#F26D24"
  };

  return (
    <Box border='1px solid' borderColor="#D1E0FF" height='fit-content' borderRadius='20px' mb={6}>
      <Box width='100%' borderTopRadius='20px' borderBottom='1px solid' bg='#F5F8FF' borderColor="#D1E0FF" display='flex' alignItems='center' justifyContent='center'>
        <Box p={4} borderRight='1px solid #D1E0FF' width='40%'>
          <Text color='Black' fontSize="16px" fontWeight='600' textAlign='justify'> {techIndicator} </Text>
        </Box>
        <Box width='30%' borderRight='1px solid #D1E0FF' p={4}>
          <Text color='black' fontWeight='500' fontSize="14px" >Maturity Level: <Text as='span' fontWeight='500' fontSize="14px" color={statusColors[maturityLevel]}> {maturityLevel} </Text> </Text>
        </Box>
        <Box width='30%' p={4}>
          <Text color='black' fontWeight='500' fontSize="14px" >Original Score: <Text as='span' fontWeight='500' fontSize="14px" color={statusColors[maturityLevel]}> {techIndicatorScore} </Text> </Text>
        </Box>
      </Box>
      <Box p={4}>
        {strengthSummary.length > 0 && (
          <Box pb={2}>
            <Text color='black' fontWeight='500' fontSize="14px" textAlign='justify'>
              Strength Summary
            </Text>
            <Box px={6} py={1}>
              <UnorderedList>
                {strengthSummary.map((point, index) => (
                  <ListItem key={index} fontSize="14px" fontWeight="500" color='#3e424a' lineHeight='21px' textAlign='justify'>{point}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          </Box>
        )}
        {improvementsNeeded.length > 0 && (
          <Box pb={2}>
            <Text color='black' fontWeight='500' fontSize="14px" textAlign='justify'>
              Improvements Needed
            </Text>
            <Box px={6} py={1}>
              <UnorderedList>
                {improvementsNeeded.map((point, index) => (
                  <ListItem key={index} fontSize="14px" fontWeight="500" color='#3e424a' lineHeight='21px' textAlign='justify'>{point}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          </Box>
        )}
      </Box>
      {isAdmin && (
        <Box p={4} borderTop='1px solid' borderColor="#D1E0FF">
          <Box onClick={handleEditAssertionClick} cursor='pointer' width='fit-content' height='fit-content'display='flex' justifyItems='flex-start' alignItems='center'>
            <Icon as={EditIcon} boxSize={4} color='#2970FF' cursor='pointer'  />
            <Text pl={1} color='#2970FF' fontSize="14px" fontWeight='500' textAlign='justify'>Edit</Text>
          </Box>
        </Box>
      )}
      <EditTechIndicatorModal 
        isOpen={isEditAssertionOpen} 
        onClose={onEditAssertionClose} 
        reportId={reportId} techIndicatorId={techIndicatorId} 
        strengthSummary={strengthSummary} 
        improvementsNeeded={improvementsNeeded}
        refreshData={refreshData}
      />
    </Box>
  );
};

function TechIndicator() {

  const { reportId = 1 } = useParams();
  const [ indicators, setIndicators] = useState([]);

  const fetchData = async () => {
    try {
      const response = await get(`report/techindicator/${reportId}`);
      setIndicators(response.data.data.techIndicators)

    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [reportId]);

  return (
    <ChakraProvider>
      <Box px={10} py={10} w='100%' h='fit-content'>
        <Heading fontSize="24px" fontWeight='700' color="black" mb={1}>Tech Indicators</Heading>
        <br />
        <Box>
          {indicators.map((indicator, index) => (
            <IndicatorBox
              key={index}
              reportId={reportId}
              techIndicatorId ={indicator.techIndicatorId}
              techIndicator={indicator.techIndicator}
              maturityLevel={indicator.maturityLevel}
              techIndicatorScore={indicator.techIndicatorScore}
              strengthSummary={indicator.strengthSummary}
              improvementsNeeded={indicator.improvementsNeeded}
              refreshData={fetchData}
            />
          ))}
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default TechIndicator;
